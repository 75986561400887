<div class="correction-information">
  <mat-expansion-panel [(expanded)]="isExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title class="section-label"> Correction </mat-panel-title>
    </mat-expansion-panel-header>
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="25">
          <div class="label">PRO</div>
          <div class="value" data-testid="pro">
            <a xpo-regular-link href="" (click)="onProLinkClicked($event)"
              [matTooltip]="'Navigate to '+proNbr?.formatProNumber()+' shipment details'">
              {{ proNbr?.formatProNumber() ?? '-' }}
            </a>
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Comments</div>
          <div class="value" data-testid="comments">
            {{ correctionRequest?.requestorComment ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Status</div>
          <div class="value" data-testid="status">
            {{ CorrectionReviewStatusText[correctionRequest?.reviewStatusCd] ?? '-' }}
          </div>
        </div>
        <div fxLayout="column" fxFlex="25">
          <div class="label">Updated Date</div>
          <div class="value" data-testid="updated-date">
            {{ correctionRequest?.auditInfo?.updatedTimestamp | date: 'short' ?? '-' }}
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="100">
          <div class="correction-information__comments">
            <div class="label">Review Comments</div>
            <mat-form-field>
              <textarea
                matInput
                placeholder=""
                maxlength="4000"
                [(ngModel)]="reviewComments"
                (focusout)="reviewCommentsChanged.emit(reviewComments)"
                [disabled]="isReviewCommentsFieldDisabled$ | async"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="2"
                cdkAutosizeMaxRows="100"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="stretch start">
        <div fxLayout="column" fxFlex="100">
          <xpo-card type="data" color="">
            <xpo-card-header>
              <xpo-card-title>General Information</xpo-card-title>
            </xpo-card-header>
          
            <xpo-card-content>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenCorrectionsApp($event)">Open Edge Corrections</a>
              </div>
              <div>
                <a class="normal_height_row" xpo-regular-link href="" color="accent" (click)="onOpenCorrectionRequestDialog($event)">Open Correction Request Details</a>
              </div>
            </xpo-card-content>
          </xpo-card>
        </div>
        
      </div>
    </div>
  </mat-expansion-panel>
</div>