import { Component, Input, OnInit } from '@angular/core';
import { List } from 'immutable';
import { BehaviorSubject, Observable, Subject, take, takeUntil } from 'rxjs';
import { Photo } from '../../../../classes/photos/photo';
import { PhotoId } from '../../../../classes/photos/photo-id';
import { DocumentListImpl } from '../../../../components/document-list/document-list-impl';
import { PhotoGalleryService } from '../../../../components/photo-gallery/photo-gallery.service';
import { DocumentListTitles } from '../../../../enums/document-list-titles.enum';
import { DocumentManagementService } from '../../../../services/api/document-management-service';
import { PhotoDownloadService } from '../../../../services/photos/photo-download.service';
import { DmsDocType } from '../../../../enums/dms-doc-type.enum';
import { ShipmentDetailsComponent } from '../../../../components/shipment-details/shipment-details.component';

@Component({
  selector: 'app-xpo-card',
  templateUrl: './xpo-card.component.html',
  styleUrls: ['./xpo-card.component.scss']
})
export class InspectionXpoCardComponent implements OnInit {
  @Input()
  title: string;

  photosSubject: BehaviorSubject<Immutable.List<Photo>>;
  photoCountNumber: number = 0;

  private unsubscriber$: Subject<void> = new Subject();
  private archivedDocumentsGalleryData: DocumentListImpl;
  private photosObservable: Observable<List<Photo>>;
  private photos: List<Photo>;
  private docTypes: DmsDocType[];

  constructor(
    private documentManagementService: DocumentManagementService,
    private photoGalleryService: PhotoGalleryService,
    private photoDownloadService: PhotoDownloadService
  ) {
    this.archivedDocumentsGalleryData = new DocumentListImpl(this.documentManagementService);
  }

  ngOnInit(): void {
    switch (this.title) {
      case DocumentListTitles.DOCUMENT:
        this.photosSubject = this.photoDownloadService.documentPhotosSubject;
        this.docTypes = ShipmentDetailsComponent.DOCUMENT_DOC_TYPES;
        break;
      case DocumentListTitles.OPERATIONS_PHOTOS:
        this.photosSubject = this.photoDownloadService.operationPhotosSubject;
        this.docTypes = ShipmentDetailsComponent.OPERATIONS_PHOTOS_DOC_TYPES;
        break;
      case DocumentListTitles.INSPECTIONS_PHOTOS:
        this.photosSubject = this.photoDownloadService.inspectionPhotosSubject;
        this.docTypes = ShipmentDetailsComponent.INSPECTIONS_PHOTOS_DOC_TYPES;
        break;
    }

    this.photoCountNumber = this.photosSubject?.value?.size;
    this.subscribePhoto();
  }

  ngOnDestroy(): void {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  openPhotoGallery(currentPhotoId: PhotoId) {
    this.photoGalleryService
      .openPhotoGalleryDialog(this.archivedDocumentsGalleryData, this.docTypes, currentPhotoId, this.photos)
      .pipe(take(1))
      .subscribe();
  }

  private subscribePhoto() {
    this.photosObservable = this.photosSubject?.asObservable();
    this.photosObservable?.pipe(takeUntil(this.unsubscriber$)).subscribe((listOfPhotos: List<Photo>) => {
      this.photos = listOfPhotos;
    });
  }
}
