import { Correction, InspectionShipment } from '@xpo-ltl/sdk-inspections';
import { CorrectionType } from '../enums/correction-type.enum';
import { InspectionState, toInspectionStateDisplayName } from '../enums/inspection-state.enum';
import { ListName } from '../enums/list-name.enum';
import { SpacedStringFromTitleCasePipe } from '../pipes/toSpacedString.pipe';
import { ProNumber } from './pronumber';
import { LocationBreakdown, LocationUtil } from './utils/location-utils';

export class InspectionListItem {
  static readonly OPEN_DIALOG = 'Open';

  proNumber = '';
  inspectionStatus = '';
  inspectionStatusCd = '';
  location = '';
  billStatus = '';
  shipperName = '';
  consigneeName = '';
  inspectorName = '';
  transactionDate: Date;
  priorityShipment = false; // Priority shipment is those that will be highlighted in Orange
  trailerStatus = '';
  trailerNumber = '';
  locationPriority: number;
  breakDoor: number;
  loadDoor: number;
  locationWithInspectionPriority: string;
  shipmentStatus = '';
  correctionRequestStatus = '';
  correctionRequestRouterLink = '';

  constructor(inspectionShipment: InspectionShipment) {
    if (!inspectionShipment) {
      return;
    }
    this.proNumber = inspectionShipment.shipmentId
      ? new ProNumber(inspectionShipment.shipmentId.proNumber).formatProNumber()
      : '';
    this.inspectionStatusCd = inspectionShipment?.inspectionStatusCd;
    this.inspectionStatus = toInspectionStateDisplayName(this.inspectionStatusCd as InspectionState);
    this.locationWithInspectionPriority = inspectionShipment.shipmentLocation;

    const locationBreakdown: LocationBreakdown = LocationUtil.buildLocationBreakdown(
      inspectionShipment.shipmentLocation
    );
    this.location = locationBreakdown?.shipmentLocation;
    this.locationPriority = locationBreakdown?.locationPriority;
    this.trailerNumber = inspectionShipment?.trailerNbr;
    this.shipmentStatus = locationBreakdown?.shipmentStatus;
    this.trailerStatus = inspectionShipment?.scheduleStatusCd
      ? inspectionShipment?.scheduleStatusCd
      : inspectionShipment?.trailerStatusCd;

    const currentCorrectionRequestStatus: string =
      inspectionShipment.correctionRequestsForShipment?.currentCorrectionRequestStatusCd;
    const spacedStringFromTitleCasePipe: SpacedStringFromTitleCasePipe = new SpacedStringFromTitleCasePipe();
    this.correctionRequestStatus = spacedStringFromTitleCasePipe.transform(currentCorrectionRequestStatus);
    this.correctionRequestRouterLink = inspectionShipment.correctionRequestsForShipment?.correctionRequestAllowedInd
      ? InspectionListItem.OPEN_DIALOG
      : '';

    !isNaN(+inspectionShipment.breakDoor)
      ? (this.breakDoor = +inspectionShipment.breakDoor)
      : (this.breakDoor = locationBreakdown.breakDoorNumber);
    !isNaN(+inspectionShipment.loadDoor) ? (this.loadDoor = +inspectionShipment.loadDoor) : (this.loadDoor = undefined);
    this.billStatus = inspectionShipment.billStatusCd ? inspectionShipment.billStatusCd : '';
    this.shipperName = inspectionShipment.shipperAcctId ? inspectionShipment.shipperAcctId.acctName : '';
    this.consigneeName = inspectionShipment.consigneeAcctId ? inspectionShipment.consigneeAcctId.acctName : '';
    let inspectorName = '';
    if (inspectionShipment.inspectionContext) {
      if (inspectionShipment.inspectionContext?.inspectorFirstNm) {
        inspectorName = inspectionShipment.inspectionContext.inspectorFirstNm;
      }
      if (inspectionShipment.inspectionContext?.inspectorLastNm) {
        if (inspectorName.length > 0) {
          inspectorName += ' ';
        }
        inspectorName += inspectionShipment.inspectionContext.inspectorLastNm;
      }

      this.inspectorName = inspectorName;
    }
    this.transactionDate = inspectionShipment.lastUpdateDateTime;

    // Is this a priority Shipment?  We check all States even though not all matter here to Match the Planning Lists
    // If you change here, you probably need to change the same method in the PlanningItem class
    if (inspectionShipment.shipmentLocation) {
      const locationNumber = inspectionShipment.shipmentLocation.toString().substring(0, 1);
      const inspectionState = inspectionShipment.inspectionStatusCd;
      if (locationNumber >= '1' && locationNumber <= '4') {
        if (
          !inspectionState ||
          inspectionState === InspectionState.RECOMMENDED ||
          inspectionState === InspectionState.FLAGGED ||
          inspectionState === InspectionState.IN_PROGRESS ||
          inspectionState === InspectionState.DISMISSED ||
          inspectionState === InspectionState.NO_STATUS
        ) {
          this.priorityShipment = true;
        }
      }
    }
  }

  public static sortArray(inspectionListItems: InspectionListItem[], listName: ListName) {
    if (!inspectionListItems) {
      return;
    }

    if (listName === ListName.COMPLETED) {
      inspectionListItems.sort((shipment1, shipment2) => {
        // The object is defined as a date, but is really a number, so need to cast
        const date1 = <number>(shipment1.transactionDate ? shipment1.transactionDate : 0);
        const date2 = <number>(shipment2.transactionDate ? shipment2.transactionDate : 0);
        return date2 - date1; // descending
      });
    } else if (listName === ListName.INSPECTED) {
      inspectionListItems.sort((shipment1, shipment2) => {
        // The object is defined as a date, but is really a number, so need to cast
        const status1 = shipment1.inspectionStatus ? shipment1.inspectionStatus : '';
        const status2 = shipment2.inspectionStatus ? shipment2.inspectionStatus : '';

        if (status1 === status2) {
          const shipper1 = shipment1.shipperName ? shipment1.shipperName : '';
          const shipper2 = shipment2.shipperName ? shipment2.shipperName : '';
          return shipper1.localeCompare(shipper2);
        } else {
          return status1.localeCompare(status2);
        }
      });
    }
  }

  public static mapInspectionShipments(inspectionShipments: InspectionShipment[]): InspectionListItem[] {
    const planningListItems = new Array<InspectionListItem>();

    if (inspectionShipments) {
      inspectionShipments.forEach((inspectionShipment) => {
        planningListItems.push(new InspectionListItem(inspectionShipment));
      });
    }

    return planningListItems;
  }
}
