import { Injectable } from '@angular/core';
import { DataOptions } from '@xpo-ltl/data-api';
import {
  BroadcastMessage,
  CreateArchiveDmsDocumentPath,
  CreateArchiveDmsDocumentResp,
  CreateArchiveDmsDocumentRqst,
  CreateBroadcastMessageResp,
  CreateBroadcastMessageRqst,
  CreateCustomerGuidelineResp,
  CreateCustomerGuidelineRqst,
  CreateInspectionCorrectionResp,
  CreateInspectionCorrectionRqst,
  CreateRecommendationRuleResp,
  CreateRecommendationRuleRqst,
  CustomerGuideline,
  DeleteCustomerGuidelinePath,
  DeleteRecommendationRulePath,
  EnrichedCustomerGuideline,
  GetCorrectionRequestPath,
  GetCorrectionRequestRatePreviewPath,
  GetCorrectionRequestRatePreviewResp,
  GetCorrectionRequestResp,
  GetInspectionForCorrectionRequestPath,
  GetInspectionForCorrectionRequestResp,
  GetLastRefreshRecommendationsInfoPath,
  GetLastRefreshRecommendationsInfoResp,
  InspectionContext,
  InspectionCorrectionRequest,
  InspectionsApiService,
  ListBroadcastMessagesQuery,
  ListBroadcastMessagesResp,
  ListCustomerGuidelinesQuery,
  ListCustomerGuidelinesResp,
  ListRecommendationRulesBySicPath,
  ListRecommendationRulesBySicResp,
  ListUserBroadcastMessagesPath,
  ListUserBroadcastMessagesResp,
  UpdateCustomerGuidelinePath,
  UpdateCustomerGuidelineResp,
  UpdateCustomerGuidelineRqst,
  UpdateRecommendationRulePath,
  UpdateRecommendationRuleResp,
  UpdateRecommendationRuleRqst,
  UpsertInspectionCorrectionRequestResp,
  UpsertInspectionCorrectionRequestRqst
} from '@xpo-ltl/sdk-inspections';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { CustomerGuidelineWithAcct } from '../../classes/customer-guideline-with-acct';
import { CustomerRecommendedRule } from '../../classes/customer-recommended-rule';
import { ApiError } from '../../classes/error/api/api-error';
import { InspectionError } from '../../classes/error/inspection-error';
import { Photo } from '../../classes/photos/photo';
import { ProNumber } from '../../classes/pronumber';
import { ApiUtil } from '../../classes/utils/api-util';
import { InspectionsDateUtils } from '../../classes/utils/inspections-date-utils';
import { ErrorMessageActions } from '../../enums/error-message-actions.enum';
import { InspectionErrorCdEnum } from '../../enums/inspection-error-code.enum';
import { RetryStrategyService } from '../../operators/retry-strategy.service';
import { RequestValidator } from '../../validators/request.validator';
import { ShipmentDetailsService } from '../api/shipment-details.service';
import { AppConstantsService } from '../app-constants.service';
import { RefreshDatesService } from '../refresh-dates.service';
import { SnackBarHandlingService } from '../snack-bar-handling.service';

@Injectable({ providedIn: 'root' })
export class InspectionsApiWrapperService {
  private readonly CUSTOMER_GUIDELINES: string = 'Customer Guidelines';
  private readonly RECOMMENDATION_RULES: string = 'Recommendation Rules';
  private readonly ARCHIVE_DMS_DOCUMENT: string = 'Archive DMS Document';
  private readonly BROADCAST_MESSAGE: string = 'Broadcast Message';
  private readonly LOADED_SHIPMENTS_BY_DOOR: string = 'Loaded shipments by door';
  private readonly LOADED_SHIPMENTS_BY_TRAILER: string = 'Loaded shipments by trailer';

  constructor(
    private inspectionsApiService: InspectionsApiService,
    private retryStrategy: RetryStrategyService,
    private refreshDatesService: RefreshDatesService,
    private appConstantsService: AppConstantsService,
    private snackBarHandlingService: SnackBarHandlingService
  ) {}

  createCustomerGuideline(aCustomerGuideline: CustomerGuideline): Observable<CreateCustomerGuidelineResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aCustomerGuideline, 'Customer Guideline');

    const createCustomerGuidelineRqst = new CreateCustomerGuidelineRqst();
    createCustomerGuidelineRqst.customerGuideline = aCustomerGuideline;

    return this.inspectionsApiService.createCustomerGuideline(createCustomerGuidelineRqst).pipe(
      catchError((error) => {
        this.snackBarHandlingService.handleResponseError(
          error,
          ErrorMessageActions.CREATING,
          this.CUSTOMER_GUIDELINES,
          true
        );

        return EMPTY;
      })
    );
  }

  listCustomerGuidelines(): Observable<CustomerGuidelineWithAcct[]> {
    return this.inspectionsApiService.listCustomerGuidelines(new ListCustomerGuidelinesQuery()).pipe(
      map((resp: ListCustomerGuidelinesResp) =>
        resp.customerGuideline?.map((item: EnrichedCustomerGuideline) => {
          const itemView: CustomerGuidelineWithAcct = new CustomerGuidelineWithAcct();
          itemView.setFromEnrichedCustomerGuideline(item);
          return itemView;
        })
      )
    );
  }

  getCustomerGuidelineByCustomerId(customerId: string): Observable<EnrichedCustomerGuideline> {
    RequestValidator.validateStringNotNullOrEmpty(customerId, 'Customer ID');

    const query = new ListCustomerGuidelinesQuery();
    query.acctInstId = customerId;

    return this.inspectionsApiService.listCustomerGuidelines(query).pipe(
      map((response: ListCustomerGuidelinesResp) => {
        if (response?.customerGuideline?.length > 0) {
          return response.customerGuideline[0];
        }
      })
    );
  }

  updateCustomerGuideline(
    aCustomerGuideline: CustomerGuideline,
    aAcctInstId: string
  ): Observable<UpdateCustomerGuidelineResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aCustomerGuideline, 'Customer Guideline');
    RequestValidator.validateStringNotNullOrEmpty(aAcctInstId, 'Account Instance ID');

    const request = new UpdateCustomerGuidelineRqst();
    const path = new UpdateCustomerGuidelinePath();
    request.customerGuideline = aCustomerGuideline;
    path.acctInstId = aAcctInstId;

    return this.inspectionsApiService.updateCustomerGuideline(request, path);
  }

  deleteCustomerGuideline(aAcctInstId: string): Observable<void> {
    RequestValidator.validateStringNotNullOrEmpty(aAcctInstId, 'Account Instance ID');

    const deleteCustomerGuidelinePath = new DeleteCustomerGuidelinePath();
    deleteCustomerGuidelinePath.acctInstId = aAcctInstId;

    return this.inspectionsApiService.deleteCustomerGuideline(deleteCustomerGuidelinePath);
  }

  listRecommendationRulesBySic(inspectionSic: string): Observable<CustomerRecommendedRule[]> {
    RequestValidator.validateStringNotNullOrEmpty(inspectionSic, 'Inspection SIC');

    const path: ListRecommendationRulesBySicPath = new ListRecommendationRulesBySicPath();
    path.sicCd = inspectionSic;

    return this.inspectionsApiService.listRecommendationRulesBySic(path).pipe(
      map((resp: ListRecommendationRulesBySicResp) =>
        resp.recommendationRules.map((rule: CustomerRecommendedRule) => {
          const newRuleToShow: CustomerRecommendedRule = new CustomerRecommendedRule();
          this.refreshDatesService.setRecommendationRuleListRefreshDate(new Date());
          newRuleToShow.map(rule);
          return newRuleToShow;
        })
      )
    );
  }

  createRecommendationRule(
    aRecommendationRule: CustomerRecommendedRule,
    options?: DataOptions
  ): Observable<CreateRecommendationRuleResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aRecommendationRule, 'Recommendation Rule');

    const createRecommendationRuleRqst = new CreateRecommendationRuleRqst();
    createRecommendationRuleRqst.recommendationRule = aRecommendationRule;

    return this.inspectionsApiService.createRecommendationRule(createRecommendationRuleRqst, options).pipe(
      catchError((error) => {
        this.snackBarHandlingService.handleResponseError(
          error,
          ErrorMessageActions.CREATING,
          this.RECOMMENDATION_RULES
        );

        return EMPTY;
      })
    );
  }

  updateRecommendationRule(
    ruleInstId: string,
    aRecommendationRule: CustomerRecommendedRule,
    options?: DataOptions
  ): Observable<UpdateRecommendationRuleResp> {
    RequestValidator.validateStringNotNullOrEmpty(ruleInstId, 'Recommendation Rule ID');
    RequestValidator.validateObjectNotUndefinedOrEmpty(aRecommendationRule, 'Recommendation Rule');

    const request = new UpdateRecommendationRuleRqst();
    const path = new UpdateRecommendationRulePath();
    path.customerRcmndRuleId = ruleInstId;
    request.recommendationRule = aRecommendationRule;

    return this.inspectionsApiService.updateRecommendationRule(request, path, options);
  }

  deleteRecommendationRule(aCustomerRcmndRuleId: number): Observable<void> {
    RequestValidator.validateNumberIsPositiveOrZeroInteger(aCustomerRcmndRuleId, 'Recommendation Rule ID number');

    const path = new DeleteRecommendationRulePath();
    path.customerRcmndRuleId = aCustomerRcmndRuleId;

    return this.inspectionsApiService.deleteRecommendationRule(path);
  }

  createArchiveDmsDocument(photo: Photo, aInspectionSic: string): Observable<CreateArchiveDmsDocumentResp> {
    RequestValidator.validateStringNotNullOrEmpty(aInspectionSic, 'Inspection Sic');
    RequestValidator.validateObjectNotUndefinedOrEmpty(photo, 'Photo');

    const path: CreateArchiveDmsDocumentPath = new CreateArchiveDmsDocumentPath();
    const request: CreateArchiveDmsDocumentRqst = new CreateArchiveDmsDocumentRqst();
    path.proNumber = photo.id?.proNumber?.formatProNumber();
    path.inspectionSic = aInspectionSic;
    request.filename = photo.fileName;
    request.fileContents = photo.getBase64data();
    request.contentType = photo.contentType;
    if (request.filename?.trim().length === 0) {
      request.filename = photo.id?.id;
    }

    return this.inspectionsApiService.createArchiveDmsDocument(request, path, { loadingOverlayEnabled: false });
  }

  createBroadcastMessage(aBroadcastMessage: BroadcastMessage): Observable<CreateBroadcastMessageResp> {
    RequestValidator.validateObjectNotUndefinedOrEmpty(aBroadcastMessage, 'Broadcast Message');

    const request = new CreateBroadcastMessageRqst();
    request.broadcastMessage = aBroadcastMessage;

    return this.inspectionsApiService.createBroadcastMessage(request);
  }

  listBroadcastMessages(query: ListBroadcastMessagesQuery): Observable<ListBroadcastMessagesResp> {
    return this.inspectionsApiService.listBroadcastMessages(query);
  }

  listUserBroadcastMessages(path: ListUserBroadcastMessagesPath): Observable<ListUserBroadcastMessagesResp> {
    return this.inspectionsApiService.listUserBroadcastMessages(path);
  }

  updateLastRefreshRecommendationsDate(): void {
    this.appConstantsService.validateSic();

    const request: GetLastRefreshRecommendationsInfoPath = new GetLastRefreshRecommendationsInfoPath();
    request.inspectionSic = this.appConstantsService.inspectionContext?.inspectionSic;
    this.inspectionsApiService
      .getLastRefreshRecommendationsInfo(request, ApiUtil.DEFAULT_DATA_OPTIONS)
      .pipe(
        this.retryStrategy.retryStrategy(
          ShipmentDetailsService.DEFAULT_GET_TIMEOUT,
          ShipmentDetailsService.DEFAULT_RETRY_DELAY,
          ShipmentDetailsService.DEFAULT_RETRY_COUNT,
          null,
          'GET-LAST-REFRESH-RECOMMENDATIONS-INFO'
        ),
        take(1)
      )
      .subscribe((response: GetLastRefreshRecommendationsInfoResp) => {
        if (response) {
          //response.lastRefreshRecommendationsTmst is not a date, but number...
          const lDate: Date = InspectionsDateUtils.toDateIfNumberOrDate(response.lastRefreshRecommendationsTmst);
          this.refreshDatesService.setLastRefreshRecommendationsDate(lDate);
        }
      });
  }

  getInspectionForCorrectionRequest(
    proNumberString: string,
    sicString: string
  ): Observable<GetInspectionForCorrectionRequestResp> {
    RequestValidator.validateStringNotNullOrEmpty(proNumberString, 'PRO number');
    RequestValidator.validateStringNotNullOrEmpty(sicString, 'Inspection SIC');

    const request: GetInspectionForCorrectionRequestPath = new GetInspectionForCorrectionRequestPath();
    request.proNbr = proNumberString;
    request.sicCd = this.appConstantsService.inspectionContext?.inspectionSic;

    return this.inspectionsApiService.getInspectionForCorrectionRequest(request, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      take(1),
      catchError((error) => {
        if (error?.code === '404' && error?.error?.errorCode === InspectionErrorCdEnum.SHIPMENT_INSPECTION_NOT_FOUND) {
          return of(new GetInspectionForCorrectionRequestResp());
        } else {
          this.snackBarHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `Inspection Correction Request`,
            true
          );

          throw new ApiError(`Error ${ErrorMessageActions.GETTING} Inspection Correction Request`, error);
        }
      })
    );
  }

  getCorrectionRequest(requestId: number): Observable<GetCorrectionRequestResp> {
    RequestValidator.validateNumberIsPositiveInteger(requestId, 'Correction Request ID');

    const path: GetCorrectionRequestPath = new GetCorrectionRequestPath();
    path.correctionRequestId = requestId;

    return this.inspectionsApiService.getCorrectionRequest(path, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      take(1),
      catchError((error) => {
        this.snackBarHandlingService.handleResponseError(
          error,
          ErrorMessageActions.GETTING,
          `Inspection Correction Request`,
          true
        );

        throw new ApiError(`Error ${ErrorMessageActions.GETTING} Correction Request for ID: ${requestId}`, error);
      })
    );
  }

  upsertInspectionCorrectionRequest(
    inspectionCorrectionRequest: InspectionCorrectionRequest
  ): Observable<UpsertInspectionCorrectionRequestResp> {
    RequestValidator.validateStringNotNullOrEmpty(
      inspectionCorrectionRequest?.correctionRequest?.correctionTypeCd,
      'correction type code'
    );

    const request: UpsertInspectionCorrectionRequestRqst = new UpsertInspectionCorrectionRequestRqst();
    request.inspectionCorrectionRequest = new InspectionCorrectionRequest();
    request.inspectionCorrectionRequest = inspectionCorrectionRequest;

    return this.inspectionsApiService.upsertInspectionCorrectionRequest(request, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      take(1),
      catchError((error) => {
        if (error.code === '400') {
          const errorMessageFromBE: string = error.error?.message;

          throw new InspectionError(errorMessageFromBE);
        } else {
          this.snackBarHandlingService.handleResponseError(
            error,
            ErrorMessageActions.SAVING,
            `Inspection Correction Request`,
            true
          );

          throw new InspectionError(`Error ${ErrorMessageActions.SAVING} Inspection Correction Request`, error);
        }
      })
    );
  }

  createInspectionCorrection(
    proNbr: string,
    inspectionContext: InspectionContext,
    correctionRequestId: number,
    correctionTypeCd: string
  ): Observable<CreateInspectionCorrectionResp> {
    RequestValidator.validateProNumber(new ProNumber(proNbr));
    RequestValidator.validateObjectNotUndefinedOrEmpty(inspectionContext, 'Inspection Context');
    RequestValidator.validateNumberIsPositiveInteger(correctionRequestId, 'Inspection Request ID');

    const request: CreateInspectionCorrectionRqst = new CreateInspectionCorrectionRqst();
    request.proNbr = proNbr;
    request.inspectionContext = inspectionContext;
    request.correctionRequestId = correctionRequestId;
    request.correctionTypeCd = correctionTypeCd;

    return this.inspectionsApiService.createInspectionCorrection(request, ApiUtil.DEFAULT_DATA_OPTIONS); // error is handled on component level
  }

  getCorrectionRequestRatePreview(requestId: number): Observable<GetCorrectionRequestRatePreviewResp> {
    RequestValidator.validateNumberIsPositiveInteger(requestId, 'Rate preview request ID');

    const request: GetCorrectionRequestRatePreviewPath = new GetCorrectionRequestRatePreviewPath();
    request.correctionRequestId = requestId;

    return this.inspectionsApiService.getCorrectionRequestRatePreview(request, ApiUtil.DEFAULT_DATA_OPTIONS).pipe(
      take(1),
      catchError((error) => {
        if (error?.code === '400') {
          const errorLocation: string = this.snackBarHandlingService.buildDisplayErrorMessage(
            ErrorMessageActions.GETTING,
            `Auto Rated Preview`
          );

          const moreInfoErrorMessage: string = `\n${error?.error?.moreInfo?.[0]?.message}`;

          const errorMessage: string = this.snackBarHandlingService.buildResponseErrorMessage(
            errorLocation,
            moreInfoErrorMessage
          );

          throw new ApiError(errorMessage);
        } else {
          this.snackBarHandlingService.handleResponseError(
            error,
            ErrorMessageActions.GETTING,
            `Auto Rated Preview`,
            true
          );
          throw new ApiError(`Error ${ErrorMessageActions.GETTING} Auto Rated Preview`, error);
        }
      })
    );
  }
}
