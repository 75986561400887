import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { InspectionShipmentDetails } from '../../../../classes/inspection-shipment-details';

@Component({
  selector: 'app-inspection-corrections',
  templateUrl: './inspection-corrections.component.html',
  styleUrls: ['./inspection-corrections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InspectionCorrectionsCardComponent implements OnChanges {
  @HostBinding('class') hostClass = 'ins-corrections-card';

  @Input()
  shipmentDetails: InspectionShipmentDetails;

  isExpanded = true;

  ngOnChanges() {
    if (this.shipmentDetails) {
      this.sortToAscOrderByCorrectionCreatedTimestamp();
    }
  }

  private sortToAscOrderByCorrectionCreatedTimestamp() {
    this.shipmentDetails.corrections?.sort(
      (a, b) =>
        new Date(a?.auditInfo?.createdTimestamp)?.getTime() - new Date(b?.auditInfo?.createdTimestamp)?.getTime()
    );
  }
}
