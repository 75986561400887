import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DocumentListTitles } from '../../enums/document-list-titles.enum';

@Component({
  selector: 'app-inspection-supporting-documents-dialog',
  templateUrl: './inspection-supporting-documents-dialog.component.html',
  styleUrls: ['./inspection-supporting-documents-dialog.component.scss']
})
export class InspectionSupportingDocumentsDialogComponent {
  DocumentListTitles = DocumentListTitles;

  constructor() {}
}
