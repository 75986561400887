import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { AppConstantsService } from './app-constants.service';
import { InspectionError } from '../classes/error/inspection-error';
import { InspectionNotificationEvent } from '../classes/inspectionnotification/inspection-notification-event';

/**
 * Using that as an injected service will be working for some cases, but not where we want to use it into a specific class
 * unless we pass that as parameter all along which might be a pain, that's why I used a static instance set
 * in appComponent
 */

@Injectable({
  providedIn: 'root'
})
export class InspectionNotificationService {
  //set by initializer
  private static _appInstance: InspectionNotificationService = undefined;

  static set appInstance(value: InspectionNotificationService) {
    this._appInstance = value;
  }

  static get appInstance(): InspectionNotificationService {
    if (!this._appInstance) {
      const lErr: InspectionError = new InspectionError('appInstance');
      console.error('appInstance called but not set yet', lErr);
    }
    return this._appInstance;
  }

  constructor(protected userService: UserService, protected appConstantsService: AppConstantsService) {}

  logShouldBeFixed(s: string) {
    console.error('Should be fixed: ' + this.getMessagePrefix() + ': ' + s);
  }

  private getMessagePrefix(): string {
    //todo add user and SIC to log to an API
    return 'inspectionContext:' + JSON.stringify(this.appConstantsService.inspectionContext);
  }

  addEvent(evt: InspectionNotificationEvent) {
    evt.logToConsole();
    //todo complete me, add what needs to be done here
  }
}
