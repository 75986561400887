import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CorrectionRequest } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject } from 'rxjs';
import { ProNumber } from '../../../../classes/pronumber';
import { AppNavigationService } from '../../../../services/app-navigation.service';
import { RequestValidator } from '../../../../validators/request.validator';
import { ExternalAppAccessService } from '../../../../services/externalAppAccess.service';
import { DialogWrapperService } from '../../../../services/dialog-wrapper.service';
import { InspectionCorrectionService } from '../../../../components/inspection-corrections/service/inspection-correction.service';
import { InspectionCorrectionsComponent } from '../../../../components/inspection-corrections/inspection-corrections.component';
import { CorrectionReviewStatusText } from '../../../../enums/correction-review-status.enum';
import { CorrectionRequestReviewStatusCd } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-correction-information',
  templateUrl: './correction-information.component.html',
  styleUrls: ['./correction-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CorrectionInformationComponent implements OnChanges {
  @Input()
  correctionRequest: CorrectionRequest;
  proNbr$ = new BehaviorSubject<ProNumber>(null);
  @Output()
  reviewCommentsChanged = new EventEmitter<string>();
  @Output()
  closeDialog = new EventEmitter<boolean>();
  reviewComments: string;
  isReviewCommentsFieldDisabled$ = new BehaviorSubject<boolean>(true);
  isExpanded = true;
  private readonly PRO_NUMBER: string = 'PRO number';
  readonly CorrectionReviewStatusText = CorrectionReviewStatusText;

  get proNbr(): ProNumber {
    return this.proNbr$.value;
  };

  ngOnChanges(changes: SimpleChanges) {
    if(changes['correctionRequest']) {
      this.reviewComments = this.correctionRequest?.reviewComment;
      this.isReviewCommentsFieldDisabled$.next(this.correctionRequest?.reviewStatusCd !== CorrectionRequestReviewStatusCd.IN_REVIEW);
      this.proNbr$.next(new ProNumber(this.correctionRequest?.proNbr));
    }
  }

  constructor(
    private appNavigation: AppNavigationService,
    private externalAppAccessService: ExternalAppAccessService,
    private dialogWrapperService: DialogWrapperService,
    private inspectionCorrectionService: InspectionCorrectionService
  ) {}

  onProLinkClicked(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    this.appNavigation.navigateToShipmentDetails(this.proNbr);
    this.closeDialog.emit(true);
  }

  onOpenCorrectionsApp(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();

    const formattedProNumberString = this.proNbr.formatProNumber();
    RequestValidator.validateStringNotNullOrEmpty(formattedProNumberString, this.PRO_NUMBER);
    const shipmentManagementAppBaseUrl: string = this.externalAppAccessService.buildCorrectionsAppUrl(
      formattedProNumberString
    );

    window.open(shipmentManagementAppBaseUrl);
  }

  onOpenCorrectionRequestDialog(mouseEvent: MouseEvent) {
    mouseEvent.preventDefault();
    this.inspectionCorrectionService.setCorrectionRequestId(+this.correctionRequest?.correctionRequestId);
    this.inspectionCorrectionService.setIsInspectionCorrectionsComponentReadOnly(true);
    this.dialogWrapperService.alert(
      {
        titleText: `Details for Correction Request ID: ${this.correctionRequest?.correctionRequestId}`,
        contentComponent: InspectionCorrectionsComponent,
        hideDismissButton: true
      },
      {
        disableClose: false,
        minWidth: '95vw'
      }
    );
  }
}
