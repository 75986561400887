import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputNumberModule } from '@deividfortuna/input-number';
import {
  CustomerApiService as CustomerV2ApiService,
  SdkCustomerModule as SdkCustomerV2Module
} from '@xpo-ltl-2.0/sdk-customer';
import { LocationApiService as LocationV2ApiService, SdkLocationModule } from '@xpo-ltl-2.0/sdk-location';
import { ConditioningService, FormatValidationService } from '@xpo-ltl/common-services';
import {
  CONFIG_MANAGER_SERVICE_CONFIGURATION,
  ConfigManagerModule,
  ConfigManagerService
} from '@xpo-ltl/config-manager';
import { DataApiService, NotificationService } from '@xpo-ltl/data-api';
// TODO: maint ui needs to use latest firestore version
// import { SupportedAppEnum, XpoMaintAppConfig, XpoMaintenanceModule } from '@xpo-ltl/maint-ui';
import {
  XPO_LTL_AUTH_FIRESTORE_CONFIG,
  XpoLtlAuthFirestoreConfig,
  XpoLtlAuthFirestoreModule
} from '@xpo-ltl/ngx-ltl-auth/firestore';
import { XpoLtlAuthUiModule } from '@xpo-ltl/ngx-ltl-auth/ui';
import { XpoAccountPopoverModule } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoAppSwitcherPopoverModule } from '@xpo-ltl/ngx-ltl-core/app-switcher-popover';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoContextualHeaderModule } from '@xpo-ltl/ngx-ltl-core/contextual-header';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { XpoPageNotFoundRoutingModule } from '@xpo-ltl/ngx-ltl-core/error-page';
import { XpoFeedbackModule } from '@xpo-ltl/ngx-ltl-core/feedback';
import { XpoFooterModule } from '@xpo-ltl/ngx-ltl-core/footer';
import { XpoIconModule } from '@xpo-ltl/ngx-ltl-core/icon';
import { XpoShellModule } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { XpoSlideToggleModule } from '@xpo-ltl/ngx-ltl-core/toggle';
import { SdkAppMetadataModule } from '@xpo-ltl/sdk-appmetadata';
import { BeaconApiService } from '@xpo-ltl/sdk-beacon';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { HumanResourceApiService } from '@xpo-ltl/sdk-humanresource';
import { InfrastructureApiService } from '@xpo-ltl/sdk-infrastructure';
import { InspectionsApiService, SdkInspectionsModule } from '@xpo-ltl/sdk-inspections';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ElasticSearchApiService } from '@xpo-ltl/sdk-elasticsearch';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from '../environments/environment';
import { AppDialogsModule } from './app-dialogs.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddProResolverService } from './components/add-pro-detail/service/add-pro-resolver.service';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { CameraService } from './components/camera/camera.service';
import { CorrectionRequestDialogComponent } from './components/correction-request-dialog/correction-request-dialog.component';
import { CustomerGuidelinesService } from './components/customer-guidelines/services/customer-guidelines.service';
import { InsContextHeaderComponent } from './components/ins-context-header/ins-context-header.component';
import { InspectShipmentModule } from './components/inspect-shipment/inspect-shipment.module';
import { InspectionDetailDialogColumnComponent } from './components/inspection-detail-dialog-column/inspection-detail-dialog-column.component';
import { ListInspectionCorrectionsModule } from './components/list-inspection-corrections/list-inspection-corrections.module';
import { ListCompleteInspectionsComponent } from './components/list-shipments/list-completed-inspections/list-completed-inspections.component';
import { ListDismissedShipmentsComponent } from './components/list-shipments/list-dismissed-shipments/list-dismissed-shipments.component';
import { ListFlaggedShipmentsComponent } from './components/list-shipments/list-flagged-shipments/list-flagged-shipments.component';
import { ListInspectionsComponent } from './components/list-shipments/list-inspected-inspections/list-inspected-inspections.component';
import { ListRecommendedShipmentComponent } from './components/list-shipments/list-recommended-shipments/list-recommended-shipments.component';
import { ListShipmentsComponent } from './components/list-shipments/list-shipments.component';
import { NmfcColumnComponent } from './components/list-shipments/nmfc-column/nmfc-column.component';
import { RouterLinkColumnComponent } from './components/list-shipments/router-link-column/router-link-column.component';
import { LoadingOverlayModule } from './components/loading-overlay/loading-overlay.module';
import { NotAuthorizedComponent } from './components/not-authorized/not-authorized.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PhotosStatusModule } from './components/photos-status/photos-status.module';
import { RecommendedRulesService } from './components/recommendation-rules/services/recommended-rules.service';
import { ShipmentDetailsModule } from './components/shipment-details/shipment-details.module';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { Item680CalculatorModule } from './components/tools/item680calculator/item680calculator.module';
import { Item680ValidationsService } from './components/tools/item680calculator/services/item680-validations.service';
import { TrailerDetailResolver } from './components/trailer-detail/resolver/trailer-detail.resolver';
import { DialogsModule } from './dialogs/dialogs.module';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ConfigManagerProperties } from './enums/config-manager-properties.enum';
import { InputValidationModule } from './input-validation/input-validation.module';
import { MaterialModule } from './material.module';
import { RetryStrategyService } from './operators/retry-strategy.service';
import { XpoPipesModule } from './pipes/xpo-pipes.module';
import { DocumentManagementService } from './services/api/document-management-service';
import { InspectionDetailService } from './services/api/inspection-detail.service';
import { InspectionShipmentDetailsService } from './services/api/inspection-shipment-details.service';
import { ShipmentDetailsService } from './services/api/shipment-details.service';
import { AppConstantsService } from './services/app-constants.service';
import { AppNavigationService } from './services/app-navigation.service';
import { AppStorageService } from './services/app-storage.service';
import { CustomerAccountService } from './services/customer-account.service';
import { EmailService } from './services/email/email.service';
import { GridSettingsService } from './services/grid-settings/grid-settings.service';
import { MobileWebBrowserService } from './services/hardware/mobile-web-browser-service';
import { InspectionDefaultErrorHandlerService } from './services/inspection-default-error-handler.service';
import { InspectionLocalStorageService } from './services/inspection-local-storage.service';
import { InspectionNotificationService } from './services/inspection-notification.service';
import { InspectionsDefaultHttpInterceptor } from './services/inspections-default-http-interceptor';
import { InspectionsApiWrapperService } from './services/inspections/inspections-api-wrapper.service';
import { PhotoDownloadService } from './services/photos/photo-download.service';
import { PhotoEventService } from './services/photos/photo-event.service';
import { PhotoUploadService } from './services/photos/photo-upload.service';
import { RefreshDatesService } from './services/refresh-dates.service';
import { SnackBarHandlingService } from './services/snack-bar-handling.service';
import { RequestValidator } from './validators/request.validator';
import { InspectionsApiCorrectionsWrapperService } from './services/api/inspections-api-corrections-wrapper.service';


export function authConfigFactory(configManagerService: ConfigManagerService): XpoLtlAuthFirestoreConfig {
  return new XpoLtlAuthFirestoreConfig(
    {
      appCode: configManagerService.getSetting('authAppCode'),
      scopes: configManagerService.getSetting('authScopes'),
      isProd: configManagerService.getSetting('authIsProd'),
      applicationRootUri: configManagerService.getSetting('authAppRootUri')
    },
    {
      appName: configManagerService.getSetting('appName'),
      apiUri: configManagerService.getSetting('apiUrl'),
      scopeOptions: configManagerService.getSetting('scopeOptions'),
      authGrantType: configManagerService.getSetting('authGrantType')
    },
    {
      firebaseOptions: environment.firebase,
      region: configManagerService.getSetting(ConfigManagerProperties.REGION)
    }
  );
}

/**
 * https://angular.io/api/core/APP_INITIALIZER
 *
 * Initialize your application here!
 *
 * @param constantsService
 * @param inspectionsApiWrapperService
 * @param snackBarHandlingService
 */
export function initalizeApplication(
  constantsService: AppConstantsService,
  inspectionsApiWrapperService: InspectionsApiWrapperService,
  snackBarHandlingService: SnackBarHandlingService
): () => Promise<any> {
  return (): Promise<any> => {
    try {
      // console.debug('initalizeApplication');
      constantsService.inspectionContext$.subscribe(() => {
        if (constantsService.hasSicAndShift()) {
          inspectionsApiWrapperService.updateLastRefreshRecommendationsDate();
        }
      });
      return undefined;
    } catch (e) {
      const msg: string = 'initalizeApplication error';
      snackBarHandlingService.handleError(msg, e);
      throw e;
    }
  };
}

const sdkModules: any[] = [SdkAppMetadataModule, SdkLocationModule, SdkInspectionsModule, SdkCustomerV2Module];

const ltlCoreModules = [
  XpoAccountPopoverModule,
  XpoAppSwitcherPopoverModule,
  XpoDialogModule,
  XpoFeedbackModule,
  XpoShellModule,
  XpoSnackBarModule,
  XpoIconModule,
  XpoFooterModule,
  XpoContextualHeaderModule,
  XpoButtonModule,
  XpoSlideToggleModule,
  XpoLtlAuthUiModule
];

@NgModule({
  declarations: [
    AppComponent,
    InsContextHeaderComponent,
    AppFooterComponent,
    NotAuthorizedComponent,
    NotificationComponent,
    ListShipmentsComponent,
    ListRecommendedShipmentComponent,
    ListFlaggedShipmentsComponent,
    ListDismissedShipmentsComponent,
    ListInspectionsComponent,
    NmfcColumnComponent,
    ListCompleteInspectionsComponent,
    ClickOutsideDirective,
    RouterLinkColumnComponent,
    CorrectionRequestDialogComponent,
    InspectionDetailDialogColumnComponent
  ],
  imports: [
    // TODO: main ui needs to use the lastest firestore version
    // provideFirebaseApp((injector) => {
    //   return initializeApp(enviroment.firebaseOptions);
    // }),
    BrowserModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MaterialModule,
    ConfigManagerModule,
    DialogsModule,
    XpoPipesModule,
    // TODO: maint ui needs to use latest firestore version
    // XpoMaintenanceModule.initialize(<XpoMaintAppConfig>{ appName: SupportedAppEnum.IG }),
    ...ltlCoreModules,
    ...sdkModules,
    AppDialogsModule,
    AgGridModule.withComponents([]),
    ToolbarModule,
    InputNumberModule,
    InputValidationModule,
    Item680CalculatorModule,
    ShipmentDetailsModule,
    InspectShipmentModule,
    ListInspectionCorrectionsModule,
    PhotosStatusModule,
    LoadingOverlayModule,
    XpoLtlAuthFirestoreModule.forRoot({
      provide: XPO_LTL_AUTH_FIRESTORE_CONFIG,
      useFactory: authConfigFactory,
      deps: [ConfigManagerService]
    }),
    AppRoutingModule,
    XpoPageNotFoundRoutingModule // KEEP LAST!!!
  ],
  providers: [
    {
      provide: AppConstantsService,
      useFactory: (configManagerService: ConfigManagerService, snackBarHandlingService: SnackBarHandlingService) => {
        return new AppConstantsService(configManagerService, snackBarHandlingService);
      },
      deps: [ConfigManagerService, SnackBarHandlingService]
    },
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' }
    },
    {
      provide: ErrorHandler,
      useClass: InspectionDefaultErrorHandlerService
    },
    MobileWebBrowserService,
    GridSettingsService,
    DataApiService,
    NotificationService,
    CustomerV2ApiService,
    LocationV2ApiService,
    SnackBarHandlingService,
    FormatValidationService,
    ConditioningService,
    ShipmentDetailsService,
    DocumentManagementApiService,
    DocumentManagementService,
    InspectionLocalStorageService,
    PhotoUploadService,
    RetryStrategyService,
    AppStorageService,
    TrailerDetailResolver,
    AddProResolverService,
    AppNavigationService,
    Item680ValidationsService,
    RecommendedRulesService,
    CustomerGuidelinesService,
    InspectionsApiService,
    BeaconApiService,
    HumanResourceApiService,
    CustomerAccountService,
    CameraService,
    RequestValidator,
    InfrastructureApiService,
    EmailService,
    PhotoDownloadService,
    InspectionDetailService,
    InspectionShipmentDetailsService,
    RefreshDatesService,
    InspectionNotificationService,
    ElasticSearchApiService,
    PhotoEventService,
    InspectionsApiCorrectionsWrapperService,
    //generic app initializer
    {
      provide: APP_INITIALIZER,
      useFactory: initalizeApplication,
      multi: true,
      deps: [AppConstantsService, InspectionsApiWrapperService, SnackBarHandlingService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InspectionsDefaultHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
