import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA
} from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import { DialogAction, toDialogActionPastTense } from '../../enums/dialog-actions.enum';
import { BehaviorSubject, Subject } from 'rxjs';
import { InspectionsApiCorrectionsWrapperService } from '../../services/api/inspections-api-corrections-wrapper.service';
import { CorrectionRequest, DimensionerInspectionDetail } from '@xpo-ltl/sdk-inspections';
import { CorrectionRequestReviewStatusCd } from '@xpo-ltl/sdk-common';
import { List } from 'immutable';
import { SnackBarHandlingService } from '../../services/snack-bar-handling.service';

@Component({
  selector: 'app-correction-approval-dialog',
  templateUrl: './correction-approval-dialog.component.html',
  styleUrls: ['./correction-approval-dialog.component.scss']
})
export class CorrectionApprovalDialogComponent implements OnDestroy {
  title: string;
  private unsubscriber$: Subject<void> = new Subject();
  isConfirmMessageDisplayed$ = new BehaviorSubject<boolean>(false);
  selectedAction$ = new BehaviorSubject<DialogAction>(null);
  warningActionLabel$ = new BehaviorSubject<string>('');
  correctionRequest$ = new BehaviorSubject<CorrectionRequest>(null);
  dimensionerInspectionDetails$ = new BehaviorSubject<List<DimensionerInspectionDetail>>(List());
  isReviewCorrectionActionExecutable$ = new BehaviorSubject<boolean>(false);
  
  readonly CorrectionRequestReviewStatusCd = CorrectionRequestReviewStatusCd;
  readonly DialogAction = DialogAction;
  reviewComments: string;
  correctionRequestId: number;
  
  get selectedAction(): DialogAction {
    return this.selectedAction$.value;
  };

  constructor(
    private dialogRef: MatDialogRef<CorrectionApprovalDialogComponent>,
    private inspectionsApiCorrectionsWrapperService: InspectionsApiCorrectionsWrapperService,
    private snackBarHandlingService: SnackBarHandlingService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      const dimensionerInspectionId: number = data.correction?.parentDimensionerInspectionId;
      this.correctionRequestId = data.correction?.correctionRequestId;
      this.title = `${data.title}`;
      this.correctionRequest$.next(data.correction);
      this.isReviewCorrectionActionExecutable$.next(data.correction?.reviewStatusCd === CorrectionRequestReviewStatusCd.IN_REVIEW);

      this.inspectionsApiCorrectionsWrapperService.getDimensionerInspection(dimensionerInspectionId).pipe(take(1)).subscribe((resp) => {
        if(resp) {
          this.dimensionerInspectionDetails$.next(List(resp.dimensionerInspection?.dimInspectionDetail));
        }
      })
    }
  }

  ngOnDestroy() {
    this.unsubscriber$.next();
    this.unsubscriber$.complete();
  }

  reviewCommentsChanged(comments: string) {
    this.reviewComments = comments;
  }

  public onYesButtonClicked() {
    let correctionRequestReviewStatusCd: CorrectionRequestReviewStatusCd;

    switch (this.selectedAction) {
      case DialogAction.SAVE:
        correctionRequestReviewStatusCd = CorrectionRequestReviewStatusCd.IN_REVIEW;
        break;
      case DialogAction.APPROVE:
        correctionRequestReviewStatusCd = CorrectionRequestReviewStatusCd.APPROVED;
        break;
      case DialogAction.DECLINE:
        correctionRequestReviewStatusCd = CorrectionRequestReviewStatusCd.DECLINED;
        break;
      default:
        break;
    }

    this.inspectionsApiCorrectionsWrapperService.reviewCorrectionAction(this.reviewComments, correctionRequestReviewStatusCd, this.correctionRequestId)
      .pipe(
        take(1)
      ).subscribe(() => {
        this.snackBarHandlingService.success(`The correction was ${toDialogActionPastTense(this.selectedAction as DialogAction)} successfully`);
        this.close(true);
      });
  }

  public onNoButtonClicked() {
    this.isConfirmMessageDisplayed$.next(false);
  }

  public close(dialogCloseResult: boolean) {
    this.dialogRef.close(dialogCloseResult);
  }
  
  onSelectAction(action: DialogAction) {
    this.selectedAction$.next(action)
    this.warningActionLabel$.next(`Are you sure you want to ${action} this Auto-Correction?`)
    this.isConfirmMessageDisplayed$.next(true);
  }
}
