<mat-dialog-actions>
  <div class="content-left">
    <h2 mat-dialog-title>{{ title }}</h2>
  </div>
</mat-dialog-actions>
<br />
<mat-divider></mat-divider>
<mat-dialog-content>
  <div *ngIf="!(isConfirmMessageDisplayed$ | async)">
    <app-correction-information (closeDialog)="close(false)" [correctionRequest]="correctionRequest$ | async" (reviewCommentsChanged)="reviewCommentsChanged($event)"></app-correction-information>
    <app-dimensioner-inspection-detail-list [dimensionerInspectionDetailListInput]="dimensionerInspectionDetails$ | async"></app-dimensioner-inspection-detail-list>
  </div>
  <div *ngIf="isConfirmMessageDisplayed$ | async">
    <div class="warning">
      <p>{{ warningActionLabel$ | async }}</p>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="content-left">
    <ng-container *ngIf="!(isConfirmMessageDisplayed$ | async) && (isReviewCorrectionActionExecutable$ | async)">
      <button mat-flat-button color="warn" (click)="onSelectAction(DialogAction.DECLINE)">Decline</button>
    </ng-container>
  </div>
  <div class="content-right">
    <ng-container *ngIf="!(isConfirmMessageDisplayed$ | async) && (isReviewCorrectionActionExecutable$ | async)">
      <button mat-button (click)="close(false)">Cancel</button>
      <button
        mat-button
        color="primary"
        (click)="onSelectAction(DialogAction.SAVE)"
      >
        Save
      </button>
      <button
        mat-button
        color="primary"
        (click)="onSelectAction(DialogAction.APPROVE)"
      >
        Approve
      </button>
    </ng-container>
    <!--    yes/no buttons are displayed when save clicked as intermediate screen-->
    <ng-container *ngIf="isConfirmMessageDisplayed$ | async">
      <button (click)="onNoButtonClicked()" mat-button>No</button>
      <button (click)="onYesButtonClicked()" mat-button color="primary">Yes</button>
    </ng-container>
    <!-- If the review status is InReview we cannot take any action  -->
    <button mat-button *ngIf="!(isReviewCorrectionActionExecutable$ | async)" (click)="close(false)">Close</button>
  </div>
</mat-dialog-actions>