<mat-expansion-panel [(expanded)]="isExpanded">
  <mat-expansion-panel-header>
    <mat-panel-title class="section-label"> Inspection Corrections </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-panel-title class="sub-label"> Submitted Corrections </mat-panel-title>
  <div *ngIf="shipmentDetails?.corrections?.size > 0; else noSubmittedCorrections">
    <div class="label">
      <div class="correction_card">Correction Type</div>
      <div class="correction_card">Correction Status</div>
      <div class="correction_card">Auto Correction</div>
      <div class="correction_card__currency">Revenue Impact</div>
      <div class="correction_card">Requestor Name</div>
      <div class="correction_card">Inspection SIC</div>
      <div class="correction_card">Submitted Date</div>
      <div class="correction_card">Last Update</div>
    </div>

    <div *ngFor="let correction of shipmentDetails?.corrections" class="correction_values">
      <div class="correction_card">
        {{ correction.shortDescription }}
      </div>
      <div matTooltip="{{ correction.statusCd | toTooltipMessageFromCorrectionStatus }}" class="correction_card">
        {{ correction.statusCd | toMessageFromCorrectionStatus }}
      </div>
      <div class="correction_card">
        {{ correction.autoCorrectionInd }}
      </div>
      <div class="correction_card__currency">
        {{ correction.revenueImpactAmount | toUsCurrency }}
      </div>
      <div class="correction_card">
        {{ correction.requestorFullName }}
      </div>
      <div class="correction_card">
        {{ correction.sicCd }}
      </div>
      <div class="correction_card">
        {{ correction.auditInfo.createdTimestamp | dateMilitaryFormat }}
      </div>
      <div class="correction_card">
        {{ correction.auditInfo.updatedTimestamp | dateMilitaryFormat }}
      </div>
    </div>
  </div>
  <ng-template #noSubmittedCorrections> No Submitted Corrections </ng-template>

  <mat-panel-title class="sub-label__correctionRequest"> In Progress Corrections Requests </mat-panel-title>

  <div *ngIf="shipmentDetails?.correctionRequests?.size > 0; else noInProgressCorrectionsRequests">
    <div class="label">
      <div class="correction_card">Correction Type</div>
      <div class="correction_card">Correction Status</div>
      <div class="correction_card">Auto Correction</div>
      <div class="correction_card">Requestor Name</div>
      <div class="correction_card">Inspection SIC</div>
      <div class="correction_card">Last Update</div>
    </div>

    <div *ngFor="let correctionRequest of shipmentDetails?.correctionRequests" class="correction_values">
      <div class="correction_card">
        {{ correctionRequest.shortDescription }}
      </div>
      <div class="correction_card">{{ correctionRequest.statusCd }}</div>
      <div class="correction_card">
        {{ correctionRequest.autoIndYesNoString }}
      </div>
      <div class="correction_card">
        {{ correctionRequest.requestorFullName }}
      </div>
      <div class="correction_card">
        {{ correctionRequest.parentInspectionSicCd }}
      </div>
      <div class="correction_card">
        {{ correctionRequest.auditInfo?.updatedTimestamp | dateMilitaryFormat }}
      </div>
    </div>
  </div>
  <ng-template #noInProgressCorrectionsRequests> No In Progress Corrections Requests </ng-template>
</mat-expansion-panel>
