import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DimensionerInspectionDetail, DimensionerInspectionDetailDoc } from '@xpo-ltl/sdk-inspections';
import { Photo } from '../../../../classes/photos/photo';
import { PhotoId } from '../../../../classes/photos/photo-id';
import { DmsOpCode } from '../../../../enums/dms-op-code.enum';
import { ProNumber } from '../../../../classes/pronumber';
import { BehaviorSubject, forkJoin, map, take } from 'rxjs';
import { DocumentManagementService } from '../../../../services/api/document-management-service';
import { DmsDocType } from '../../../../enums/dms-doc-type.enum';
import { List } from 'immutable';

interface DocumentWithPhoto {doc: DimensionerInspectionDetailDoc, photo: Photo}

@Component({
  selector: 'app-dimensioner-inspection-detail',
  templateUrl: './dimensioner-inspection-detail.component.html',
  styleUrls: ['./dimensioner-inspection-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionerInspectionDetailComponent implements OnChanges {
  @Input()
  dimensionerInspectionDetailInput: DimensionerInspectionDetail;

  dimensionerInspectionDetail$ = new BehaviorSubject<DimensionerInspectionDetail>(null);
  matPanelTitleContent$ = new BehaviorSubject<string>(null);
  documentWithPhotoArray$ = new BehaviorSubject<List<DocumentWithPhoto>>(List());
  isExpanded = false;

  constructor(private documentManagementService: DocumentManagementService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dimensionerInspectionDetailInput']) {
      const dimensionerInspectionDetailId = this.dimensionerInspectionDetailInput?.dimensionerInspectionDetailId;
      const childProNbr = this.dimensionerInspectionDetailInput?.childProNbr;
      let detailIdTitle = '';
      let childProNbrTitle = '';
      let titlePipe = '';
      
      if(dimensionerInspectionDetailId) {
        detailIdTitle = `Detail ID - ${dimensionerInspectionDetailId}`;
      }

      if(childProNbr) {
        childProNbrTitle = `Child PRO - ${childProNbr}`;
      }
      
      if(detailIdTitle && childProNbrTitle) {
        titlePipe = ' | ';
      }

      this.dimensionerInspectionDetail$.next(this.dimensionerInspectionDetailInput);
      this.matPanelTitleContent$.next(`${detailIdTitle}${titlePipe}${childProNbrTitle}`);
      this.loadArchivedDocuments();
    }
  }
  
  loadArchivedDocuments() {
    const observableBatch = this.dimensionerInspectionDetailInput?.dimInspectionDetailDoc.map((doc: DimensionerInspectionDetailDoc) => {
      return this.documentManagementService.getArchivedDdfoDocument(doc.dimensionDocId).pipe(
        map((archivedDoc:any) => {
          const photoId = new PhotoId(new ProNumber(doc.dimensionDocId), DmsDocType.DOCK_DIMENSIONER_FOTO);
          const photo = new Photo(photoId);
          photo.dmsOpCode = DmsOpCode.NEWLY_ADDED_PHOTO;
          photo.setBase64dataAndContentType(archivedDoc?.document?.binary, Photo.CONTENT_TYPE_PNG);
          return {photo, doc} ;
        })
      )
    });
  
    forkJoin(observableBatch).pipe(take(1)).subscribe((res: DocumentWithPhoto[]) => this.documentWithPhotoArray$.next(List(res)));
  }
}
