<xpo-card type="data" color="accent">
  <xpo-card-header>
    <xpo-card-title>{{ title }} ({{ (photosSubject | async)?.size }})</xpo-card-title>
  </xpo-card-header>

  <xpo-card-content>
    <div class="contents">
      <div *ngIf="!photosSubject || (photosSubject | async)?.size === 0; else showPhotos" fxLayoutAlign="start center">
        No {{ title }} Found
      </div>
      <ng-template #showPhotos>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div *ngFor="let photo of photosSubject | async" fxLayout="column">
            <div fxFlex>
              <mat-icon
                class="contents__documentIcon"
                svgIcon="{{ photo?.svgIconValue }}"
                (click)="openPhotoGallery(photo?.id)"
                [matTooltip]="photo?.toolTip"
              >
              </mat-icon>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </xpo-card-content>
</xpo-card>
