import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DimensionerInspectionDetail } from '@xpo-ltl/sdk-inspections';
import { BehaviorSubject } from 'rxjs';
import { List } from 'immutable';

@Component({
  selector: 'app-dimensioner-inspection-detail-list',
  templateUrl: './dimensioner-inspection-detail-list.component.html',
  styleUrls: ['./dimensioner-inspection-detail-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionerInspectionDetailListComponent implements OnChanges {
  @Input()
  dimensionerInspectionDetailListInput: List<DimensionerInspectionDetail>;
  dimensionerInspectionDetailList$ = new BehaviorSubject<List<DimensionerInspectionDetail>>(List());
  isExpanded = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if(changes['dimensionerInspectionDetailListInput']) {
      this.dimensionerInspectionDetailList$.next(this.dimensionerInspectionDetailListInput);
    }
  }
}
