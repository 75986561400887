import { Component, OnDestroy } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DialogWrapperService } from '../../services/dialog-wrapper.service';
import { InspectionCorrectionsComponent } from '../inspection-corrections/inspection-corrections.component';
import { InspectionCorrectionService } from '../inspection-corrections/service/inspection-correction.service';
import { LoadingOverlayService } from '../loading-overlay/service/loading-overlay.service';

@Component({
  selector: 'correction-request-dialog',
  templateUrl: './correction-request-dialog.component.html',
  styleUrls: ['./correction-request-dialog.component.scss']
})
export class CorrectionRequestDialogComponent implements AgRendererComponent, OnDestroy {
  readonly DIALOG_NOT_AVAILABLE_FOR_AUTO_ELS = 'N/A Auto-ELS';
  cellValue: string; // value that will be displayed in column
  value: string; // we're not displaying this in the column but using this to get the data

  private readonly OPEN_DIALOG_FOR_CORRECTION_DETAILS = 'Open';

  constructor(
    private dialogWrapperService: DialogWrapperService,
    private inspectionCorrectionService: InspectionCorrectionService,
    private loadingOverlayService: LoadingOverlayService
  ) {}

  ngOnDestroy(): void {
    /**
     * set isInspectionCorrectionsComponentReadOnly to false when dialog is closed.
     *
     * InspectionCorrectionsComponent is shared between Correction Request page(editable) and Correction Request Detail dialog(readonly).
     * when initializing InspectionCorrectionsComponent, we check isInspectionCorrectionsComponentReadOnly to know which one we are opening.
     */
    this.inspectionCorrectionService.setIsInspectionCorrectionsComponentReadOnly(false);
  }

  agInit(params: ICellRendererParams): void {
    this.value = params.value;
    if (this.value === this.DIALOG_NOT_AVAILABLE_FOR_AUTO_ELS) {
      this.cellValue = this.DIALOG_NOT_AVAILABLE_FOR_AUTO_ELS;
    } else if (this.value) {
      this.cellValue = this.OPEN_DIALOG_FOR_CORRECTION_DETAILS;
    }
  }

  onOpenDialogClicked() {
    this.inspectionCorrectionService.setCorrectionRequestId(+this.value);
    this.inspectionCorrectionService.setIsInspectionCorrectionsComponentReadOnly(true);
    this.dialogWrapperService.alert(
      {
        titleText: `Details for Correction Request ID: ${this.value}`,
        contentComponent: InspectionCorrectionsComponent,
        hideDismissButton: true
      },
      {
        disableClose: false,
        minWidth: '95vw'
      }
    );
  }

  /**
   * refresh() is from ICellRenderer(base interface)
   *
   * Get the cell to refresh. Return true if successful. Return false if not (or you don't have refresh logic),
   * then the grid will refresh the cell for you.
   */
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
