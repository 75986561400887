export enum ColumnType {
  TIMESTAMP = 'Timestamp',
  CUSTOMER_INSTRUCTIONS = 'CustomerInstructions',
  NMFC = 'NMFC',
  COMMODITY_DESCRIPTION = 'CommodityDescription',
  NMFC_CLASS = 'NMFCClass',
  FAK = 'FAK',
  AUTO_CORRECTION_STATUS = 'AutoCorrectionStatus',
  INSPECTION_STATUS = 'InspectionStatus',
  PARTY_ROLE = 'PartyRole',
  PAYMENT_TYPE = 'PaymentType',
  NUMBER = 'Number',
  MODELS = 'Models',
  CURRENCY = 'Currency',
  RIGHT_ALIGNED_WITH_SET_COLUMN_FILTER = 'RightAlignedWithSetColumnFilter'
}
