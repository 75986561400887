import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { InspectionState } from '../../../enums/inspection-state.enum';
import { ListName } from '../../../enums/list-name.enum';
import { AppNavigationService } from '../../../services/app-navigation.service';
import { GridSettingsService } from '../../../services/grid-settings/grid-settings.service';
import { HardwareService } from '../../../services/hardware/hardware-service';
import { ShipmentDetailsService } from '../../../services/api/shipment-details.service';
import { DialogWrapperService } from '../../../services/dialog-wrapper.service';
import { PlanningListItemsComponent } from '../../../classes/planning-list-items-component';

@Component({
  selector: 'app-list-planning-shipments',
  templateUrl: './list-recommended-shipments.component.html',
  styleUrls: ['./list-recommended-shipments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListRecommendedShipmentComponent extends PlanningListItemsComponent implements OnInit, OnDestroy {
  @Output()
  selectedCountChanged = new EventEmitter<number>();

  @Output()
  listChanged = new EventEmitter<boolean>();

  @Output()
  gridSettingsChanged = new EventEmitter<boolean>();

  constructor(
    private shipmentDetailsService: ShipmentDetailsService,
    private gridSettings: GridSettingsService,
    private dialogManager: DialogWrapperService,
    protected appNavigation: AppNavigationService,
    protected changeDetector: ChangeDetectorRef
  ) {
    super(changeDetector, appNavigation);

    this.gridOptions.defaultColDef = this.getDefaultColDef();

    this.gridOptions.columnDefs = this.gridSettings.buildDefaultRecommendedListColumnDefs();

    this.gridOptions.rowSelection = 'multiple';
    this.gridOptions.suppressMenuHide = HardwareService.isTouchScreenCapable();
    this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptions.onCellDoubleClicked = this.onCellDoubleClicked.bind(this);
    this.gridOptions.onColumnMoved = this.columnsChanged.bind(this);
    this.gridOptions.onColumnPinned = this.columnsChanged.bind(this);
    this.gridOptions.onColumnResized = this.columnsChanged.bind(this);
    this.gridOptions.onFilterChanged = this.columnsChanged.bind(this);
    this.gridOptions.onColumnVisible = this.columnsChanged.bind(this);
    this.gridOptions.onSortChanged = this.columnsChanged.bind(this);
    this.gridOptions.onRowDataChanged = this.rowDataChanged.bind(this);
    this.gridOptions.icons = GridSettingsService.buildGridIcons();
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.ensureDomOrder = true;
    this.gridOptions.onGridReady = () => {
      this.setCurrentWidthAndHeight();
    };
  }

  private subscription = new Subscription();

  public ngOnInit() {
    this.subscription.add(
      this.shipmentDetailsService.recommendedListData$.subscribe((planningListItems) => {
        this.updateRowData(planningListItems);
      })
    );
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private rowDataChanged($event) {
    this.gridSettings.restoreGridState(ListName.RECOMMENDED, this.gridOptions);
    this.setCurrentWidthAndHeight();
  }

  private columnsChanged($event) {
    this.gridSettings.storeGridState(ListName.RECOMMENDED, this.gridOptions);
    this.gridSettingsChanged.emit(true);
  }

  onResize($event) {
    this.setCurrentWidthAndHeight();
  }

  onSelectionChanged() {
    if (this.gridOptions.api) {
      const selectedRows = this.gridOptions.api.getSelectedRows();
      if (selectedRows) {
        this.selectedCountChanged.emit(selectedRows.length);
      }
    }
  }

  private onCellDoubleClicked($event) {
    this.unselectAllRows();
    this.navigateToShipmentDetails($event.data);
  }

  public moveShipmentsToInspectionList() {
    const proNumbers = this.getSelectedShipments();
    this.shipmentDetailsService
      .updateInspectionStatus(proNumbers, InspectionState.FLAGGED)
      .pipe(take(1))
      .subscribe((response) => {
        this.dialogManager.showStatusChangeDialog(response);
        this.listChanged.emit(true);
      });
  }

  public dismissShipments() {
    const proNumbers = this.getSelectedShipments();
    this.shipmentDetailsService
      .updateInspectionStatus(proNumbers, InspectionState.DISMISSED)
      .pipe(take(1))
      .subscribe((response) => {
        this.dialogManager.showStatusChangeDialog(response);
        this.listChanged.emit(true);
      });
  }
}
